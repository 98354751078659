import LegalityWorld from "organisms/legalityWorld";
import React, { useContext } from "react";
import { LanguageContext } from "templates/LocaleBase";

import ResponsiveMedia from "utils/responsive_media";
import DescriptionWithImage from "../parts/description/descriptionWithImage";
import ImageTitle from "../parts/imageTitle";
import LegalAccordion from "./legalAccordion";

const Legality = ({ title, accordion, certify, world }) => {
    const { isKo } = useContext(LanguageContext);
    const { isMobile } = ResponsiveMedia();

    return (
        <>
            <ImageTitle data={title} />
            {isKo && <LegalAccordion data={accordion} />}
            {isKo && <DescriptionWithImage data={certify} bgColor={"#f8f8f8"} reverse textToTop />}
            <DescriptionWithImage data={world} padding={isMobile === "true" ? "80px 20px" : "130px 40px 180px"} hideLink={!isKo} />
            {!isKo && <LegalityWorld main />}
        </>
    );
};

export default Legality;
