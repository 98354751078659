import React, { useEffect, useState } from "react";
import ResponsiveMedia from "utils/responsive_media";
import Image from "components/image/image";
import { AccordionContainer, AccordionBox, AccordionBorderWrapper, AccordionWrapper, AccordionContent, AccordionHeader, ArrowImageWrapper, HeaderTitle, ContentTitle, ContentSubTitle, HeaderImgWrapper } from "./style";
import { IC_ACCORDION_ARROW, IC_ACCORDION_ENABLE_ARROW, IC_ACCORDION_OPEN_ARROW } from "../../../constants/media/icon";
import { useTranslation } from "react-i18next";

const Accordion = ({ border, data, padding, jutifyContent, alignitems, onClickEvent }) => {
    const { t } = useTranslation();

    const { isMobile } = ResponsiveMedia();
    const [active, setActive] = useState(0);

    const handleAccordion = (index) => {
        setActive(index);
        onClickEvent && onClickEvent(index);
    };

    function BorderRow(props) {
        const { row, index } = props;
        return (
            <AccordionBorderWrapper open={active === index} onClick={() => handleAccordion(index)}>
                <AccordionHeader padding={"0px 24px"}>
                    <HeaderImgWrapper>
                        <Image filename={active === index ? row.icon_act : row.icon} alt="icon" />
                    </HeaderImgWrapper>
                    <HeaderTitle open={active === index}>{isMobile === "true" && t(row.title_mobile) ? t(row.title_mobile) : t(row.title)}</HeaderTitle>
                    <Image filename={active === index ? IC_ACCORDION_OPEN_ARROW : IC_ACCORDION_ENABLE_ARROW} alt={row.title} style={isMobile === "true" ? { width: "16px", height: "16px" } : { width: "32px", height: "32px" }} />
                </AccordionHeader>
                <AccordionContent>
                    <ContentTitle>{t(row.desc)}</ContentTitle>
                </AccordionContent>
            </AccordionBorderWrapper>
        );
    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = useState(false);

        return (
            <AccordionWrapper margin={isMobile === "true" ? "0 0 16px" : "0 0 32px"} width={"100%"} open={open}>
                <AccordionHeader padding={isMobile === "true" ? "0 20px" : "0 35px"} height={isMobile === "true" ? "52px" : "68px"} justifycontent={"space-between"} onClick={() => setOpen(!open)}>
                    <HeaderTitle bold color={"#713838"} fontSize={isMobile === "true" ? "14px" : "20px"}>
                        {isMobile === "true" && t(row.title_mobile) ? t(row.title_mobile) : t(row.title)}
                    </HeaderTitle>
                    <ArrowImageWrapper open={open}>
                        <Image filename={IC_ACCORDION_ARROW} alt={row.title} style={isMobile === "true" ? { width: "16px", height: "16px" } : { width: "24px", height: "24px" }} />
                    </ArrowImageWrapper>
                    {/* <CustomAccordionArrow open={open} /> */}
                </AccordionHeader>
                <AccordionContent padding={isMobile === "true" ? "0 20px 20px" : "0 30px 20px"}>
                    <ContentTitle padding={"0 0 16px"} fontSize={isMobile === "true" ? "14px" : "18px"}>
                        {t(row.desc)}
                    </ContentTitle>
                    <ContentSubTitle fontSize={isMobile === "true" ? "12px" : "16px"}>{t(row.sub_desc)}</ContentSubTitle>
                </AccordionContent>
            </AccordionWrapper>
        );
    }

    return (
        <AccordionContainer padding={padding} justifycontent={jutifyContent} alignitems={alignitems}>
            <AccordionBox style={border ? {} : { width: "100%" }}>
                {data.map((row, index) => {
                    return <div key={index}>{border ? <BorderRow row={row} index={index} /> : <Row row={row} />}</div>;
                })}
            </AccordionBox>
        </AccordionContainer>
    );
};

export default Accordion;
