import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import Accordion from "../parts/accordion/accordion";
import { TextBold } from "components/style/textStyle";
import { Box, Container } from "../../components/style/layoutStyle";
import { useTranslation } from "react-i18next";

const LegalAccordion = ({ data }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    return (
        <Container>
            <Box flexDirection={"column"} padding={isMobile === "true" ? "80px 20px" : "130px 0"} gap={isMobile === "true" ? "50px 0" : "95px 0"}>
                <TextBold type={isMobile === "true" ? "26" : "36"} color={"#1d1d1d"} textAlign={"center"} whiteSpace={"pre-line"}>
                    {isMobile === "true" ? t(data.title_mobile) : t(data.title)}
                </TextBold>
                <Accordion data={data.items} />
            </Box>
        </Container>
    );
};

export default LegalAccordion;
