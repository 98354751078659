import styled from "styled-components";

export const AccordionContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: ${(props) => props.alignitems || "center"};
    justify-content: ${(props) => props.justifycontent};
    padding: ${(props) => props.padding};
`;

export const AccordionBox = styled.div``;

export const AccordionBorderWrapper = styled.div`
    width: ${(props) => props.width || "436px"};
    margin: 24px 0 24px 0px;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;

    ${(props) =>
        props.open
            ? `  
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #713838;
        
        > div {
            &: nth-child(2){
                margin: -10px 0 0;
                max-height: 500px;
            }
        }
    `
            : `
        border: solid 1px #ddd;
        > div {
            &: nth-child(2){
                padding: 0 40px 0;
            }
        }
    `}
`;

export const AccordionWrapper = styled.div`
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #efefef;
    background-color: #fff;
    margin: ${(props) => props.margin || "0 0 32px"};
    cursor: pointer;

    ${(props) =>
        props.open
            ? `  
        > div {
            &: nth-child(2){
                max-height: 500px;
            }
        }
    `
            : `
        > div {
            &: nth-child(2){
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    `}
`;

export const AccordionHeader = styled.div`
    width: 100%;
    height: ${(props) => props.height || "68px"};
    padding: ${(props) => props.padding || "0 35px"};
    display: flex !important;
    align-items: center;
    justify-content: ${(props) => props.justifycontent || "left"};
    gap: 8px;
`;

export const HeaderImgWrapper = styled.div`
    width: 32px;
    height: 32px;
`;

export const HeaderTitle = styled.div`
    ${(props) => (props.bold ? `font-weight:700;` : `font-weight:500;`)}
    font-size: ${(props) => props.fontSize || "18px"};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: ${(props) => props.color || "#b5b5b5"};
    flex: 1;

    ${(props) =>
        props.open
            ? `
        font-weight: bold;
        color: #713838;
    `
            : `
        font-weight: medium;
    `}
`;

export const AccordionContent = styled.div`
    width: 100%;
    padding: ${(props) => props.padding || "0 10px 20px 29px"};

    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
`;

export const ContentTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;

    font-size: ${(props) => props.fontSize || "16px"};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    line-height: 1.35;
    letter-spacing: -0.48px;
    overflow: hidden;
    white-space: pre-line;
    color: #1e1e1e;
    padding: ${(props) => props.padding || "0"};
`;

export const ContentSubTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;

    font-size: ${(props) => props.fontSize || "14px"};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.48px;
    text-align: left;
    overflow: hidden;
    white-space: pre-line;
    color: #707070;
`;

export const ArrowImageWrapper = styled.div`
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    transition: all 0.3s ease-in-out;
    ${(props) =>
        props.open
            ? `
        transform: rotate(180deg);
    `
            : null};
`;
